<template>
  <div>
    <b-card no-body class="mb-5">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <b-col cols="12" md="4" class="d-flex align-items-left justify-content-end mb-1 mb-md-0 ">
              <!-- <v-select :options="statusOptions" v-model="status" class="per-page-selector d-inline-block w-100" label="label" :reduce="(val) => val.value" placeholder="Status" /> -->
              <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="statusOptions" v-model="treeStatus" valueFormat="object" :limit="2" class="per-page-selector d-inline-block w-100" style="position: absolute" placeholder="Status" />
            </b-col>

            <b-col cols="12" md="2" class="d-flex align-items-left justify-content-start ">
              <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" router-link to="/testlease/inception/quote-add" v-if="$Can('inception_quote_create')">
                  <span class="text-nowrap">Add Inception</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative mb-5"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listSales"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="saleHeaders"
          show-empty
          empty-text="No matching records found"
          :per-page="perPage"
          @row-selected="onRowSelected"
        >
          <template #cell(deductionCredit)="data">
            <span>{{ data.item.deductionCredit ? data.item.deductionCredit : 'N/A' }}</span>
          </template>
          <!-- Column: discountRate -->
          <template #cell(discountRate)="data">
            <span>{{ data.item.discountRate ? data.item.discountRate + '%' : 'N/A' }}</span>
          </template>
          <!-- Column: lessAdditionalCredit -->
          <template #cell(lessAdditionalCredit)="data">
            <span>{{ data.item.lessAdditionalCredit ? formatPrice(data.item.lessAdditionalCredit, 2) : formatPrice(0) }}</span>
          </template>
          <!-- Column: lessDepositPayable -->
          <template #cell(lessDepositPayable)="data">
            <span>{{ data.item.lessDepositPayable ? formatPrice(data.item.lessDepositPayable, 2) : formatPrice(0) }}</span>
          </template>
          <!-- Column: purchaserCustomerId -->
          <template #cell(purchaserCustomerId)="data">
            <span>{{ data.item.purchaserCustomerId ? data.item.purchaserCustomerId : 'N/A' }}</span>
          </template>

          <!-- Column: priceContractType -->
          <template #cell(priceContractType)="data">
            <b-badge pill variant="light-primary" class="badge-glow">Lease</b-badge>
          </template>
          <!-- Column: creditIssued -->
          <template #cell(creditIssued)="data">
            <span>{{ data.item.creditIssued ? formatPrice(data.item.creditIssued, 2) : formatPrice(0) }}</span>
          </template>
          <!-- Column: gstRateVehicle -->
          <template #cell(gstRateVehicle)="data">
            <span>{{ data.item.gstRateVehicle ? data.item.gstRateVehicle : 'N/A' }} %</span>
          </template>
          <!-- Column: pstRateVehicle -->
          <template #cell(pstRateVehicle)="data">
            <span>{{ data.item.pstRateVehicle ? data.item.pstRateVehicle : 'N/A' }} % </span>
          </template>
          <!-- Column: payableBySeller -->
          <template #cell(payableBySeller)="data">
            <span>{{ data.item.payableBySeller ? data.item.payableBySeller : 'N/A' }}</span>
          </template>
          <!-- Column: id -->
          <template #cell(id)="data">
            <strong class="text-primary">#{{ Number(data.item.id) }}</strong>
          </template>
          <!-- Column: quoteDate -->
          <template #cell(quoteDate)="data">
            <span>{{ data.item.quoteDate ? dateFormat(data.item.quoteDate) : 'N/A' }}</span>
          </template>
          <!-- Column: completionDate -->
          <template #cell(completionDate)="data">
            <span>{{ data.item.completionDate ? data.item.completionDate : 'N/A' }}</span>
          </template>
          <!-- Column: location -->
          <template #cell(location)="data">
            <span>{{ data.item.location ? data.item.location : 'N/A' }}</span>
          </template>
          <!-- Column: userId -->
          <template #cell(userId)="data">
            <span>{{ data.item.userId ? data.item.userId : 'N/A' }}</span>
          </template>
          <!-- Column: condition -->
          <template #cell(condition)="data">
            <b-badge v-if="data.item.condition == 'NEW'" active variant="light-info" class="badge-glow ">New</b-badge>
            <b-badge v-if="data.item.condition == 'USED'" variant="light-success" class="badge-glow ">Used </b-badge>
          </template>

          <template #cell(coBuyerName)="data">
            <span>{{ data.item.coBuyerName ? data.item.coBuyerName : 'N/A' }}</span>
          </template>

          <template #cell(buyerType)="data">
            <b-badge v-if="data.item.buyerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
            <b-badge v-if="data.item.buyerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
          </template>

          <template #cell(lienHolder)="data">
            <span>{{ data.item.lienHolder ? data.item.lienHolder : 'N/A' }}</span>
          </template>

          <template #cell(approvedBy)="data">
            <span>{{ data.item.approvedBy ? data.item.approvedBy : 'N/A' }}</span>
          </template>

          <template #cell(totalMsrpOptions)="data">
            <span>{{ data.item.totalMsrpOptions ? data.item.totalMsrpOptions : 'N/A' }}</span>
          </template>
          <!-- Column: sellerPhoneNumber -->
          <template #cell(accessories)="data">
            <span>{{ data.item.accessories ? data.item.accessories : 'N/A' }}</span>
          </template>
          <!-- Column: sellerEmail -->
          <template #cell(buyerEmail)="data">
            <span>{{ data.item.buyerEmail ? data.item.buyerEmail : 'N/A' }}</span>
          </template>
          <!-- Column: sellerName -->
          <template #cell(buyerName)="data">
            <span>{{ data.item.buyerName ? data.item.buyerName : 'N/A' }}</span>
          </template>
          <!-- Column: sellerPhoneNumber -->
          <template #cell(buyerPhoneNumber)="data">
            <span>{{ data.item.buyerPhoneNumber ? data.item.buyerPhoneNumber : 'N/A' }}</span>
          </template>

          <template #cell(buyerTradeBalance)="data">
            <span>{{ data.item.buyerTradeBalance ? formatPrice(data.item.buyerTradeBalance, 2) : formatPrice(0) }}</span>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
            <b-badge v-if="data.item.status == 'B'" pill variant="primary" class="badge-glow">Pending manager</b-badge>
            <b-badge v-if="data.item.status == 'C'" pill variant="warning" class="badge-glow">Approved</b-badge>
            <b-badge v-if="data.item.status == 'D'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
            <b-badge v-if="data.item.status == 'E' && data.item.statusCustomer == 'blank'" pill variant="info" class="badge-glow">Sent To Client</b-badge>
            <b-badge v-if="data.item.status == 'E' && data.item.statusCustomer == 'approve'" pill variant="light-success" class="badge-glow">Sent To Client <br /><small> Client Approved</small></b-badge>
            <b-badge v-if="data.item.status == 'E' && data.item.statusCustomer == 'notapprove'" pill variant="light-danger" class="badge-glow">Sent To Client <br /><small>Client Not Approved</small></b-badge>
            <b-badge v-if="data.item.status == 'F'" pill variant="success" class="badge-glow  ">Win </b-badge>
            <b-badge v-if="data.item.status == 'P'" pill variant="secondary" class="badge">Lost</b-badge>

            <b-badge v-if="data.item.status == 'J'" pill variant="danger" class="badge">Canceled Inception</b-badge>
            <b-badge v-if="data.item.status == 'H' && data.item.excelImport == false" pill variant="success" class="badge">Win-Closed</b-badge>
            <b-badge v-if="data.item.status == 'H' && data.item.excelImport == true" pill variant="success" class="badge">Win-Closed - Excel</b-badge>
          </template>
          <!-- Column: payment -->
          <template #cell(payment)="data">
            <b-badge variant="light-success" v-if="data.item.payment == 'yes'" style="font-size: 70%">Payment Received</b-badge>
            <b-badge variant="light-danger" v-if="data.item.payment == 'no' && data.item.status == 'F'" style="font-size: 70%">Payment Pending</b-badge>
            <b-badge variant="light-info" v-if="data.item.payment == 'no' && data.item.status != 'F'" style="font-size: 70%">No Payment</b-badge>
          </template>

          <!-- Column: extColour -->
          <template #cell(extColourValue)="data">
            <span>{{ data.item.extColourValue ? data.item.extColourValue : 'N/A' }}</span>
          </template>

          <template #cell(intColourValue)="data">
            <span>{{ data.item.intColourValue ? data.item.intColourValue : 'N/A' }}</span>
          </template>

          <template #cell(inventoryLocation)="data">
            <span>{{ data.item.inventoryLocation ? data.item.inventoryLocation : 'N/A' }}</span>
          </template>

          <!-- Column: make -->
          <template #cell(makeValue)="data">
            <span>{{ data.item.makeValue ? data.item.makeValue : 'N/A' }}</span>
          </template>
          <!-- Column: model -->
          <template #cell(modelValue)="data">
            <span>{{ data.item.modelValue ? data.item.modelValue : 'N/A' }}</span>
          </template>
          <!-- Column: modelYear -->
          <template #cell(modelYearValue)="data">
            <span>{{ data.item.modelYearValue ? data.item.modelYearValue : 'N/A' }}</span>
          </template>
          <!-- Column: baseMsrp -->
          <template #cell(baseMsrp)="data">
            <span>{{ data.item.baseMsrp ? formatPrice(data.item.baseMsrp, 2) : formatPrice(0) }}</span>
          </template>

          <!-- Column: baseMsrp -->
          <template #cell(balanceDue)="data">
            <span>{{ data.item.balanceDue ? formatPrice(data.item.balanceDue, 2) : formatPrice(0) }}</span>
          </template>
          <!-- Column: msrpCurrency -->
          <template #cell(msrpCurrency)="data">
            <span>{{ data.item.msrpCurrency ? data.item.msrpCurrency : 'N/A' }}</span>
          </template>
          <!-- Column: origenalMarket -->
          <template #cell(origenalMarket)="data">
            <span>{{ data.item.origenalMarket ? data.item.origenalMarket : 'N/A' }}</span>
          </template>

          <template #cell(buyerContactInfo)="data">
            <span>{{ data.item.buyerContactInfo ? data.item.buyerContactInfo : 'N/A' }}</span>
          </template>
          <!-- Column: origenalMarket -->
          <template #cell(buyerContactPerson)="data">
            <span>{{ data.item.buyerContactPerson ? data.item.buyerContactPerson : 'N/A' }}</span>
          </template>

          <!-- Column: delivered -->
          <template #cell(delivered)="data">
            <b-badge variant="success" v-if="data.item.delivered == 'yes'" style="font-size: 70%">Vehicle Delivered</b-badge>
            <b-badge variant="danger" v-if="data.item.delivered == 'no'" style="font-size: 70%">Vehicle Not Delivered</b-badge>
          </template>

          <template #cell(consigment)="data">
            <b-badge variant="success" v-if="data.item.consigment == true" style="font-size: 70%">Consignment</b-badge>
            <b-badge variant="danger" v-if="data.item.consigment == false" style="font-size: 70%">Non Consignment</b-badge>
          </template>

          <template #cell(additionalOptions)="data">
            <span>{{ data.item.additionalOptions ? data.item.additionalOptions : 'N/A' }}</span>
          </template>
          <template #cell(additionalOptionsTotal)="data">
            <span>{{ data.item.additionalOptionsTotal ? formatPrice(data.item.additionalOptionsTotal, 2) : formatPrice(0) }}</span>
          </template>
          <template #cell(sellingPrice)="data">
            <span>{{ data.item.sellingPrice ? formatPrice(data.item.sellingPrice, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(pstAmountVehicle)="data">
            <span>{{ data.item.pstAmountVehicle ? formatPrice(data.item.pstAmountVehicle, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(gstAmountVehicle)="data">
            <span>{{ data.item.gstAmountVehicle ? formatPrice(data.item.gstAmountVehicle, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(principalBalance)="data">
            <span>{{ data.item.principalBalance ? formatPrice(data.item.principalBalance, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(residual)="data">
            <span>{{ data.item.residual ? formatPrice(data.item.residual, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(securityDeposit)="data">
            <span>{{ data.item.securityDeposit ? formatPrice(data.item.securityDeposit, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(interestCharge)="data">
            <span>{{ data.item.interestCharge ? formatPrice(data.item.interestCharge, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(contractAmount)="data">
            <span>{{ data.item.contractAmount ? formatPrice(data.item.contractAmount, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(netPayment)="data">
            <span>{{ data.item.netPayment ? formatPrice(data.item.netPayment, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(netCap)="data">
            <span>{{ data.item.netCap ? formatPrice(data.item.netCap, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(monthlyAdmin)="data">
            <span>{{ data.item.monthlyAdmin ? formatPrice(data.item.monthlyAdmin, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(dueDelivery)="data">
            <span>{{ data.item.dueDelivery ? formatPrice(data.item.dueDelivery, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(cashDown)="data">
            <span>{{ data.item.cashDown ? formatPrice(data.item.cashDown, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(buyout)="data">
            <span>{{ data.item.buyout ? formatPrice(data.item.buyout, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(basePayment)="data">
            <span>{{ data.item.basePayment ? formatPrice(data.item.basePayment, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(amortized)="data">
            <span>{{ data.item.amortized ? formatPrice(data.item.amortized, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(adminBalance)="data">
            <span>{{ data.item.adminBalance ? formatPrice(data.item.adminBalance, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(adminCharge)="data">
            <span>{{ data.item.adminCharge ? formatPrice(data.item.adminCharge, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(leasedPrice)="data">
            <span>{{ data.item.leasedPrice ? formatPrice(data.item.leasedPrice, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(creditDue)="data">
            <span>{{ data.item.creditDue ? formatPrice(data.item.creditDue, 2) : formatPrice(0) }}</span>
          </template>
          <template #cell(msrpOptionsValue)="data">
            <span>{{ data.item.msrpOptionsValue ? data.item.msrpOptionsValue : 'N/A' }}</span>
          </template>
          <template #cell(msrpTotal)="data">
            <span>{{ data.item.msrpTotal ? formatPrice(data.item.msrpTotal, 2) : formatPrice(0) }}</span>
          </template>
          <template #cell(comments)="data">
            <span>{{ data.item.comments ? data.item.comments : 'N/A' }}</span>
          </template>
          <template #cell(notes)="data">
            <span>{{ data.item.notes ? data.item.notes : 'N/A' }}</span>
          </template>

          <template #cell(listingPrice)="data">
            <span>{{ data.item.listingPrice ? formatPrice(data.item.listingPrice, 2) : formatPrice(0) }}</span>
          </template>

          <template #cell(totalTrade)="data">
            <span>{{ data.item.totalTrade ? formatPrice(data.item.totalTrade, 2) : formatPrice(0) }}</span>
          </template>

          <!-- Column: stockNumber -->
          <template #cell(stockNumber)="data">
            <span>{{ data.item.stockNumber ? data.item.stockNumber : 'N/A' }}</span>
          </template>
          <!-- Column: vinNo -->
          <template #cell(vinNo)="data">
            <span>{{ data.item.vinNo ? data.item.vinNo : 'N/A' }}</span>
          </template>
          <!-- Column: title -->
          <template #cell(title)="data">
            <span>{{ data.item.title ? data.item.title : 'N/A' }}</span>
          </template>
          <!-- Column: userName -->
          <template #cell(user)="data">
            <span>{{ data.item.user ? data.item.user : 'N/A' }}</span>
          </template>
          <!-- <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'sales-preview', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item> -->
          <!-- v-if="data.item.status == 'A'" -->
          <!-- <b-dropdown-item :to="{ name: 'sales-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit </span>
              </b-dropdown-item>
            </b-dropdown>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data" class="bg-danger">
            <b-dropdown variant="link" no-caret>
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item @click="$router.push({ name: 'testlease-preview', params: { id: data.item.id } })" v-if="data.item.archive == 'no'" v-show="$Can('inception_quote_show')">
                <feather-icon icon="EyeIcon" />

                <span class="align-middle ml-50">Quote Preview</span>
              </b-dropdown-item>

              <b-dropdown-item @click="$router.push({ name: 'inventory-view', params: { id: data.item.inventoryId } })" v-if="data.item.archive == 'no'" v-show="$Can('inventory_show')">
                <feather-icon icon="ShoppingCartIcon" />

                <span class="align-middle ml-50">Vehicle Preview </span>
              </b-dropdown-item>

              <b-dropdown-item v-show="$Can('inception_quote_edit')" v-if="data.item.status == 'A' || data.item.status == 'B' || data.item.status == 'C' || data.item.status == 'D'" @click="$router.push({ name: 'testlease-edit', params: { id: data.item.id } })">
                <feather-icon icon="EditIcon" />

                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="pdfDownload(data.item.id)" v-show="$Can('inception_quote_show')">
                <feather-icon icon="FileTextIcon" />

                <span class="align-middle ml-50">Download PDF</span>
              </b-dropdown-item>

              <b-dropdown-item @click="sendToArchive(data.item, data)" v-if="data.item.archive == 'no'" v-show="(data.item.status == 'P' || data.item.status == 'H' || data.item.status == 'J') && $Can('inception_send_archive')">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <!-- <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('view_sale_archive_records')" v-if="where == 'testlease-list'" @click="$router.push({ name: 'archive-testlease-list' })">Show archived records</a>
        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('view_sale_archive_records')" v-else @click="$router.push({ name: 'testlease-list' })">Show active records</a> -->
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import testleaseStoreModule from '@/views/testlease/testleaseStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import { provideToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import _ from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: { Treeselect, vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup, provideToast, ToastificationContent, flatPickr, moment },

  setup() {
    const SALE_APP_STORE_MODULE_NAME = 'testlease';

    // Register module
    if (!store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.registerModule(SALE_APP_STORE_MODULE_NAME, testleaseStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.unregisterModule(SALE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const tempStatusOptions = [
      { label: 'Draft', value: 'A' },
      { label: 'Pending Manager', value: 'B' },
      { label: 'Approved', value: 'C' },
      { label: 'Not Approved', value: 'D' },
      { label: 'Sent To Client', value: 'E' },
      { label: 'Win', value: 'F' },
      { label: 'Loss', value: 'P' },
      { label: 'Canceled Inception', value: 'J' },
      { label: 'Win-Closed', value: 'H' },
    ];

    return {
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      where: router.currentRoute.name,
      filter: null,

      perPage: localStorage.getItem('saleShow') ?? 10,
      fetchSale: [],
      listSales: [],
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusOptions: [],
      tempStatusOptions,
      saleHeaders: [],
      treeStatus: null,
      status: null,
      sortBy: 'id',
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('saleShow', val);
        this.listRefresh();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },
    filter: _.debounce(function(newVal) {
      this.listRefresh();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.listRefresh();
        }
      },
    },

    // status: {
    //   handler: function(val, before) {
    //     this.listRefresh();
    //   },
    // },

    treeStatus: {
      handler: function(val, before) {
        if (before != null) {
          if (val.length != before.length) {
            this.listRefresh();
          }
        }
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val.includes('to')) {
          this.filterDateStart = this.rangeDate.split(' to ')[0];
          this.filterDateEnd = this.rangeDate.split(' to ')[1];

          this.listRefresh();
        }
      },
    },
    // quoteStatus: {
    //   handler: function(val, before) {
    //     this.listSales = [];
    //     this.fetchSale.forEach((element) => {
    //       if (val == null) {
    //         if (this.filterDateStart == null && this.filterDateEnd == null) {
    //           this.listSales = this.fetchSale;
    //           this.totalRows = this.listSales.length;
    //         } else {
    //           if (element.quoteDate >= this.filterDateStart && element.quoteDate <= this.filterDateEnd) {
    //             this.listSales.push(element);
    //             this.totalRows = this.listSales.length;
    //           }
    //         }
    //       } else {
    //         if (element.status == val) {
    //           if (this.filterDateStart == null && this.filterDateEnd == null) {
    //             this.listSales.push(element);
    //             this.totalRows = this.listSales.length;
    //           } else {
    //             if (element.quoteDate >= this.filterDateStart && element.quoteDate <= this.filterDateEnd) {
    //               this.listSales.push(element);
    //               this.totalRows = this.listSales.length;
    //             }
    //           }
    //         }
    //       }
    //     });
    //   },
    // },

    // rangeDate: {
    //   handler: function(val, before) {
    //     if (this.rangeDate.includes('to')) {
    //       this.filterDateStart = this.rangeDate.split(' to ')[0];
    //       this.filterDateEnd = this.rangeDate.split(' to ')[1];

    //       if (this.filterDateStart == null && this.filterDateEnd == null) {
    //         this.listSales = this.fetchSale;
    //         this.totalRows = this.listSales.length;
    //       } else {
    //         if (this.status == null) {
    //           this.listSales = [];
    //           this.fetchSale.forEach((element) => {
    //             if (element.quoteDate >= this.filterDateStart && element.quoteDate <= this.filterDateEnd) {
    //               this.listSales.push(element);
    //             }
    //           });
    //           this.totalRows = this.listSales.length;
    //         } else {
    //           this.listSales = [];
    //           this.fetchSale.forEach((element) => {
    //             if (element.quoteDate >= this.filterDateStart && element.quoteDate <= this.filterDateEnd && element.status == this.status) {
    //               this.listSales.push(element);
    //             }
    //           });
    //           this.totalRows = this.listSales.length;
    //         }
    //       }
    //     }

    //     if (val == '' || this.filterDateEnd == '' || this.filterDateEnd == null) {
    //       this.filterDateEnd = null;
    //       this.filterDateStart = null;
    //     }
    //   },
    // },
  },

  methods: {
    listRefresh() {
      if (this.where == 'testlease-list') {
        this.getItems('getAllQuote');
      } else {
        this.getItems('getAllArchiveQuote');
      }
    },

    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD-MM-YYYY');
      }
    },

    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    pdfDownload(val) {
      var link = 'pdfDownload';
      if (this.where == 'testlease-list') {
        link = 'pdfDownload';
      } else {
        link = 'pdfArchiveDownload';
      }

      store.dispatch('testlease/' + link, val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          //  link.setAttribute('download', 'Quote.pdf');

          if (this.where == 'testlease-list') {
            link.setAttribute('download', 'Quote_' + Number(val) + '.pdf');
          } else {
            link.setAttribute('download', 'Archive_Quote_' + Number(val) + '.pdf');
          }

          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ PDF Downloaded Successfully',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });

          setTimeout(function() {
            this.show = false;

            //  router.push({ name: "inventory-edit", params: { id: response.data.inventory.id } });
            //  router.push({ name: 'inventory-list' });
          }, 1000);
        }
      });
    },

    sendToArchive(val, data) {
      this.loading = true;

      var value = {
        id: val.id,
        updated_at: val.updated_at,
      };

      store.dispatch('testlease/saleSendToArchive', value).then((response) => {
        if (response.data != 'alreadySent') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ The Quote sent to archive',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.listSales.splice((this.currentPage - 1) * this.perPage + data.index, 1);
          this.totalRows--;
          this.currentPage = 1;
          this.loading = false;
        } else {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please refresh the page.',
              text: '⚠️ This quote has already been archived.',
              icon: 'RefreshCwIcon',
              variant: 'warning',
            },
          });
        }
      });
    },

    onRowSelected(item) {
      if (item.length != 0) {
        if (this.$Can('inception_quote_show')) {
          if (this.where == 'testlease-list') {
            router.push({ name: 'testlease-preview', params: { id: item[0].id } });
          } else {
            router.push({ name: 'archive-testlease-preview', params: { id: item[0].id } });
          }
        }
      }
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    filterTreeSelect() {
      this.statusOptions = [];
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: 'Status',
          children: [],
        },
      ];

      this.tempStatusOptions.forEach((element) => {
        let setStatus = {
          id: element.value,
          label: element.label,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });

      this.statusOptions = treeOptionsStatus;
    },

    getItems(val) {
      this.loading = true;
      store
        .dispatch('testlease/' + val, {
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filter: this.filter,
          status: this.treeStatus,
          filterDateStart: this.filterDateStart,
          filterDateEnd: this.filterDateEnd,
        })
        .then((res) => {
          let filterObj = {
            perPage: this.perPage,
            status: this.treeStatus ? this.treeStatus : [],
          };

          localStorage.setItem('filterInceptionsList', JSON.stringify(filterObj));

          this.fetchSale = res.data.data.data;
          this.listSales = res.data.data.data;
          this.totalRows = res.data.data.total;

          var arrayStatus = [];
          //this.statusOptions = [];

          res.data.data.data.forEach((element) => {
            arrayStatus.push(element.status);
          });

          // const uniqeStatus = Array.from(new Set(arrayStatus));
          // this.tempStatusOptions.forEach((element) => {
          //   uniqeStatus.forEach((item) => {
          //     if (element.value == item) this.statusOptions.push(element);
          //   });
          // });
          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // getColumns(userheaders, data) {
    //   store
    //     .dispatch('sale/fetchSaleColumns', [])
    //     .then((res) => {
    //       this.createTable(userheaders, res.data);
    //       this.fetchSale = data;
    //       this.listSales = data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    toggleDetails() {},

    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          if (items == 'id' || items == 'quoteDate' || items == 'status' || items == 'priceContractType' || items == 'payment' || items == 'completionDate') {
            this.saleHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
          } else {
            this.saleHeaders.push({ key: items, label: columns.title, sortable: false, class: 'text-center', thStyle: width });
          }
        });
        if (this.$Can('inception_quote_show') || this.$Can('inception_quote_edit')) {
          this.saleHeaders.push({ key: 'actions', class: 'text-center', thStyle: 'width: 5%' });
        }
        this.loading = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    storageInceptionseItem() {
      let key = JSON.parse(localStorage.getItem('filterInceptionsList'));
      if (key == null) {
        this.listRefresh();
      }
      if (key) {
        this.perPage = key.perPage ? key.perPage : 10;
        this.page = key.page ? key.page : 1;

        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        this.treeStatus = array;
        this.listRefresh();
      }
    },
  },

  mounted() {
    this.filterTreeSelect();
    this.storageInceptionseItem();
    // this.listRefresh();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
